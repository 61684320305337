import TakeInput from './helper'
import TakeList from './helper/list'
import './take.scss'
import './dark.scss'

function Take () {
  return (
    <>
      {/* <TakeInput /> */}
      <TakeList />
    </>
  )
}

export default Take
