import InputsCourse from './helper/input'
import ListCourse from './helper/list'
import './course.scss'
import './dark.scss'

function MasterClassCategory () {
  return (
    <>
      <InputsCourse />
      <ListCourse />
    </>
  )
}

export default MasterClassCategory
