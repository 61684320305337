const arr = [
  {
    uz: {
      Xisobod: 'Xisobot',
      oqish: 'Kurslar',
      mashgulotguruhi: `Mashgulotlar guruhi`,
      guruh: 'Video',
      musobaqa: 'Musobaqa',
      musobaqaVideo: 'Musobaqa Videolari',
      Masterclass: 'Master-klass',
      MasterclassVideo: 'Master-klass video',
      kitoblarguruhi: 'Kitoblar guruhi',
      individual: 'Individual Mashgulotlar guruhi',
      individualVideo: 'Individual Mashgulotlar videosi',
      // kitob: 'Kitoblar',
      konspektlarguruhi: 'Konspektlar guruhi',
      konspekt: `Ko'nspektlar`,
      tolov: 'Kitoblar',
      davoma: 'Sotib Olish',
      allUsers: 'Hamma Users',
      openWorkbook: `Ko'nspektlar`,
      sozla: 'Sozlash',
      statistika: '2023-YIL statistikasi',
      jami: 'Jami foydalanuvchi',
      active: 'Faollar',
      hafta: 'Haftalik Daromad',
      oylik: 'Oylik Daromad',
      yillik: 'Yillik Daromad',
      courseAdd: 'Yangi Kurs qo’shish',
      title: 'Sarlavha',
      des: 'Ma’lumot',
      narx: 'Narxi',
      bgc: 'Orqa fon',
      rasm: 'Rasm',
      seq: 'Ketma-ketlik',
      yukla: 'Yuklash',
      sent: 'Yuborish',
      addVideo: 'Yangi Video qo’shish',
      duration: 'Davomiyligi',
      mCourse: 'Mavjud Kurslar',
      ismi: 'Ismi',
      familiyasi: 'Familiya',
      email: 'Email',
      open: 'Yangi Ochiq Kitob qoshish',
      workbook: 'Yangi Workbook qo‘shish'
    },

    ru: {
      Xisobod: 'Отчет',
      oqish: 'Курсы',
      mashgulotguruhi: `Учебная группа`,
      guruh: 'Bидео',
      musobaqa: 'Cоревнование',
      musobaqaVideo: 'Видео соревнований',
      Masterclass: 'Мастер класс',
      MasterclassVideo: 'Видео мастер-класса',
      kitoblarguruhi: 'Группа книг',
      individual: 'Индивидуальная группа обучения',
      individualVideo: 'Видео индивидуального обучения',
      // kitob: 'Книги',
      konspektlarguruhi: 'Konspektlar guruhi',
      konspekt: 'Konspektlar',
      tolov: 'Книги',
      davoma: 'Покупка',
      allUsers: 'Bсе Пoльзователи',
      openWorkbook: 'Konspektlar',
      sozla: 'Настройка',
      statistika: '2023 ГОД статистика',
      jami: 'Пользователи',
      hafta: 'Еженедельно',
      oylik: 'Ежемесячно',
      yillik: 'Годовой доход',
      courseAdd: 'Добавить новый курс',
      title: 'Заголовок',
      des: 'Описание',
      narx: 'Цена',
      bgc: 'Фон',
      rasm: 'Картина',
      seq: 'Последовательность',
      yukla: 'Загрузить',
      sent: 'Отправка',
      addVideo: 'Добавить новое Bидео',
      duration: 'Продолжительность',
      mCourse: 'Доступные курсы',
      ismi: 'Имя',
      familiyasi: 'Фамилия',
      email: 'Электронная почта',
      active: 'Активный',
      open: 'Добавить новую Oткрытую Kнигу',
      workbook: 'Добавить новую Kнигу'
    },

    en: {
      Xisobod: 'Report',
      oqish: 'Courses',
      mashgulotguruhi: `Training group`,
      guruh: 'Video',
      musobaqa: 'competition',
      musobaqaVideo: 'Competition Videos',
      Masterclass: 'Masterclass',
      MasterclassVideo: 'Masterclass videos',
      kitoblarguruhi: 'Books group',
      individual: 'Individual Training Group',
      individualVideo: 'Individual training video',
      // kitob: 'Books',
      konspektlarguruhi: `Ko'nspektlar group`,
      konspekt: `Ko'nspektlar`,
      tolov: 'Books',
      davoma: 'Purchase',
      allUsers: 'All Users',
      openWorkbook: `Ko'nspektlar`,
      sozla: 'Setting',
      statistika: 'YEAR 2023 statistics',
      jami: 'Total Users',
      active: 'Active',
      hafta: 'Weekly income',
      oylik: 'Monthly Income',
      yillik: 'Annual Income',
      courseAdd: 'Add a new course',
      title: 'Title',
      des: 'Description',
      narx: 'Price',
      bgc: 'Background',
      rasm: 'Picture',
      seq: 'Sequence',
      yukla: 'Download',
      sent: 'Sending',
      addVideo: 'Add a new Video',
      duration: 'Duration',
      mCourse: 'Available Courses',
      ismi: 'Name',
      familiyasi: 'Surname',
      email: 'Email',
      open: 'Add a new Open Book',
      workbook: 'Add a new Workbook'
    }
  }
]

export default arr
