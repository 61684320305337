import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { StatPriveder } from "./content/component";
import { StatePriveder } from "./content/start";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <StatePriveder>
      <StatPriveder>
        <App />
      </StatPriveder>
    </StatePriveder>
  </BrowserRouter>
);
