import InputsCourse from './helper/input'
import ListCourse from './helper/list'
import './competitionCategory.scss'
import './dark.scss'

function CompetitionCategory () {
  return (
    <>
      <InputsCourse />
      <ListCourse />
    </>
  )
}

export default CompetitionCategory
